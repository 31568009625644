import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'Português',
  serverError: 'Anormalidade no servidor, entre em contato com o administrador',
  opearteSuccess: 'Operação bem-sucedida',
  agreement: {
      agreement_1: 'Li e concordo',
      agreement_2: '\'Termo de Acordo do Usuário\'',
      agreement_3: 'e',
      agreement_4: '\'Política de Privacidade\'',
      agreement_5: 'Marque a caixa para concordar'
  },
  login: {
      webName: 'Plataforma de gerenciamento empresarial ViiTALK',
      webNameSchool: 'Plataforma de gerenciamento empresarial ViiTALK',
      website: 'Website oficial:',
      tel: 'Telefone:',
      email: 'Correspondência:',
      title: 'Login de usuário corporativo',
      titleSchool: 'Login de usuário corporativo',
      inputPlaceholder: {
          name: 'Por favor insira nome de usuário/e-mail',
          pwd: 'Por favor, insira sua senha'
      },
      button: {
          login: 'Conecte-se',
          email: 'Login por e-mail',
          account: 'Login da conta',
          register: 'Cadastro de usuário corporativo',
          registerSchool: 'Cadastro de usuário corporativo',
          pwd: 'esqueça a senha'
      },
      error: {
          uPwd: 'nome de usuário ou senha errados',
          uLock: 'A conta foi bloqueada',
          uLeave: 'Você pediu demissão e não pode usar o sistema',
          uRelogin: 'Você já está logado em outro lugar!!!',
          uReplaceLogin: 'Sua conta foi conectada em outro lugar e você foi forçado a sair. Se não foi sua culpa, sua senha pode ter vazado. Faça login novamente e altere sua senha o mais rápido possível.',
          user_null: 'por favor insira o nome de usuário',
          pwd_null: 'Por favor insira a senha'
      }
  },
  personal: {
      companyNumber: 'Empreendimento',
      schoolNumber: 'Empreendimento',
      administrators: 'administrador',
      changeLanguage: 'mudar de idioma',
      logout: 'sair'
  },
  companyMessage: {
      adminPwd: 'Senha do administrador',
      adminUserName: 'Nome do administrador',
      adminEmail: 'E-mail do administrador',
      companyFullName: 'Nome completo da empresa (nome legalmente registrado)',
      companySimpleNameCn: 'Abreviatura da empresa',
      companySimpleNameEn: 'Abreviatura da empresa (inglês)',
      companyWebsite: 'Site corporativo',
      companyNumber: 'Número do grupo empresarial',

      schoolFullName: 'Nome completo da empresa (nome legalmente registrado)',
      schoolSimpleNameCn: 'Abreviatura da empresa',
      schoolSimpleNameEn: 'Abreviatura da empresa (inglês)',
      schoolWebsite: 'Site corporativo',
      schoolNumber: 'Número do grupo empresarial',

      companyNoAuditJoin: 'Junte-se sem revisão',
      adminExplain: 'O nome do administrador fica visível para todos os membros do catálogo de endereços corporativo',
      companySimpleExplain: 'A abreviatura da empresa será exibida na página inicial do dispositivo Rooms e visível para todos os membros do catálogo de endereços da empresa.',
      button: {
          Edit: 'Rever'
      },
      dialog: {
          editpwd: {
              title: 'Alterar senha do administrador',
              label: {
                  oldPwd: 'Senha Antiga',
                  newPwd: 'Nova Senha',
                  reNewPwd: 'Confirme a nova senha'
              },
              inputPlaceholder: {
                  oldPwd: 'Por favor insira a senha antiga',
                  newPwd: 'Por favor insira uma nova senha',
                  reNewPwd: 'Por favor entre para confirmar a nova senha'
              }
          },
          editName: {
              title: 'Modificar nome do administrador',
              label: {
                  name: 'novo nome'
              },
              inputPlaceholder: {
                  name: 'Por favor insira o novo nome'
              }
          },
          editEmail: {
              title: 'Modificar e-mail do administrador',
              title2: 'Você ainda não vinculou seu endereço de e-mail. Vincule seu endereço de e-mail a tempo.',
              label: {
                  email: 'nova caixa de correio'
              },
              inputPlaceholder: {
                  email: 'Por favor insira um novo e-mail'
              }
          },
          editCompanyFullName: {
              title: 'Modifique o nome completo da empresa',
              label: {
                  name: 'novo nome completo'
              },
              inputPlaceholder: {
                  name: 'Por favor insira o novo nome completo'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'Modificar abreviatura da empresa (chinês)',
              label: {
                  name: 'Nova abreviatura'
              },
              inputPlaceholder: {
                  name: 'Por favor insira um novo nome curto'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'Modificar abreviatura da empresa (inglês)'
          },
          editWebsite: {
              title: 'Modificar endereço do site corporativo',
              label: {
                  website: 'Novo URL'
              },
              inputPlaceholder: {
                  website: 'Insira o novo URL'
              }
          },
          editNoAuditJoin: {
              title: 'Modifique o switch de adesão sem aplicativo',
              radio: {
                  open: 'Abrir',
                  close: 'fecho'
              }
          }
      },
      validator: {
          value_null: 'O valor não pode ficar vazio',
          oldPwd: {
              null: 'Por favor insira a senha antiga'
          },
          newPwd: {
              length: 'Defina uma senha com 6 caracteres ou mais de comprimento'
          },
          reNewPwd: {
              consistent: 'As senhas digitadas duas vezes são inconsistentes!'
          },
          name: {
              length: 'O nome não pode ter mais de 20 caracteres'
          },
          email: {
              rule: 'O formato do e-mail está incorreto'
          },
          NoAuditJoin: {
              null: 'Selecione um item'
          }
      }
  },
  register: {
      step_1: 'usuário e senha',
      step_2: 'informação',
      step_3: 'Terminar',
      head: 'Cadastro de usuário corporativo',
      usernameError: 'O nome do membro deve ter de 6 a 20 caracteres, sem incluir caracteres chineses e começando com uma letra.',
      usernameError_exist: 'O nome de usuário já existe, modifique seu nome de usuário',
      emailError: 'O formato do e-mail está incorreto',
      emailError_exist: 'e-mail já existe',
      passwordError_length: '6 a 20 caracteres, apenas sinais alfanuméricos e de pontuação',
      passwordError_same: 'A senha não pode ser o mesmo nome de usuário',
      passwordError: 'O formato da senha está incorreto',
      rePasswordError: 'As senhas digitadas duas vezes são inconsistentes, digite novamente',
      companyNameError: 'Por favor insira o nome completo da empresa',
      companyAbbreviationError: 'Por favor insira a abreviatura da empresa',
      orgError_exist:'A empresa foi registada, por favor altere o nome da sua empresa',
      button: {
          next: 'Próxima Etapa',
          login: 'faça login imediatamente'
      },
      inputPlaceholder: {
          username: 'nome de usuário',
          email: 'Correspondência',
          password: 'configurar senha',
          rePassword: 'Coloque a senha novamente',
          name: 'Nome completo da empresa (nome legalmente registrado)*',
          simpleNameCN: 'Abreviatura da empresa (chinês)*',
          simpleNameEN: 'Abreviatura da empresa (inglês)',
          website: 'Site da empresa (www)'
      },
      success: 'Você criou uma conta comercial com sucesso!',
      location: 'localização:',
      Scale: 'escala:'
  },
  forgotPwd: {
      head: 'Recuperação de e-mail',
      resetSuccess: 'Você redefiniu sua senha com sucesso!',
      inputPlaceholder: {
          code: 'insira o código de verificação'
      },
      sendCodeError: 'Código de verificação incorreto',
      sendCodeError_sended: 'O código de verificação foi enviado, verifique-o',
      button: {
          getCode: 'obter código de verificação',
          reGetCode: 'Obtenha o código de verificação novamente'
      }
  },
  button: {
      ok: 'confirme',
      cancel: 'Cancelar',
      search: 'procurar',
      clear: 'Claro'
  },
  device: {
      button: {
          setContent: 'Configurar agendamento push',
          setGroupMng: 'Configurar administrador de grupo',
          startNow: 'Empurre agora',
          stopNow: 'Pare de empurrar',
          add: 'Adicionar Dispositivo',
          search: 'procurar',
          adjust: 'Ajustar agrupamento',
          del: 'excluir'
      },
      inputPlaceholder: {
          search: 'Número do dispositivo/número do celular/número ViiTALK'
      },
      group: {
          add: 'Adicionar grupo',
          edit: 'Editar grupo',
          del: 'Excluir grupo'
      },
      table: {
          th_platform: 'Platform',
          th_ver: 'Versão APP',
          th_publish: 'Public/Customized Version',
          th_name: 'Nome do dispositivo',
          th_number: 'Número do dispositivo',
          th_time: 'Hora do último login',
          th_brandNumber: 'Número da casa da conferência',
          th_online: 'Está online',
          th_status: 'estado',
          th_bindNumber: 'Vincular número de tela grande'
      },
      dialog: {
          addGroupTitle: 'Por favor insira um nome de grupo',
          inputPlaceholder: {
              groupName: 'Por favor insira o nome do grupo'
          },
          delGroupTitle: 'Excluir grupo',
          delGroupTips: 'Tem certeza de que deseja excluir o grupo?',
          editGroupTitle: 'Editar grupo',
          groupMng: {
              title: 'Editar gerente de grupo',
              inputPlaceholder: {
                  name: 'Por favor insira o nome do administrador'
              },
              label: {
                  name: 'Nome do administrador'
              }
          },
          addDevice: {
              title: 'Adicionar Dispositivo',
              inputPlaceholder: {
                  name: 'Insira o nome do dispositivo',
                  number: 'Por favor insira o número do dispositivo'
              },
              label: {
                  name: 'Nome do dispositivo',
                  number: 'Número do dispositivo',
                  group: 'Selecione um grupo de dispositivos'
              }
          },
          delDevice: {
              title: 'Remover dispositivo',
              tips: 'Tem certeza de que deseja excluir o dispositivo?'
          },
          startNowDevice: {
              title: 'Empurre agora',
              tips: 'Tem certeza de que deseja forçar agora?'
          },
          stopNowDevice: {
              title: 'Pare de empurrar',
              tips: 'Tem certeza de que deseja parar de empurrar?'
          },
          adjustTitle: 'Ajustar agrupamento',
          editDevice: {
              title: 'Modificar nome do dispositivo',
              inputPlaceholder: {
                  name: 'Insira o nome do dispositivo'
              }
          },
          noChildren: {
              title: 'avisar',
              tips: 'Adicione um grupo primeiro'
          }
      },
      validator: {
          group: {
              name_null: 'O nome do grupo não pode ficar vazio',
              name_length: 'Não pode exceder 10 caracteres'
          },
          number_null: 'O número não pode ficar vazio',
          number_rule: 'Só pode ter 10 dígitos começando com 6 ou 8',
          name_null: 'O nome do terminal não pode ficar vazio'
      }
  },
  user: {
      button: {
          add: 'Adicionar usuário'
      },
      inputPlaceholder: {
          search: 'Número ViiTALK'
      },
      table: {
          th_name: 'Nome',
          th_number: 'Número de telefone celular/número Maizhe'
      },
      dialog: {
          addUser: {
              title: 'Adicionar usuário',
              inputPlaceholder: {
                  number: 'Por favor insira o número ViiTALK'
              },
              label: {
                  number: 'Número ViiTALK',
                  group: 'Selecione o grupo de usuários'
              }
          },
          delUser: {
              title: 'excluir usuários',
              tips: 'Tem certeza de que deseja excluir o usuário?'
          }
      },
      validator: {
          number_null: 'O número não pode ficar vazio',
          number_rule: 'Este número não existe'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'O grupo a ser excluído não existe',
              tips_2: 'Existem subgrupos no grupo que devem ser excluídos e não podem ser excluídos.',
              tips_3: 'Há pessoas no grupo que devem ser excluídas e não podem ser excluídas.'
          },
          addDevice: {
              tips_1: 'Já adicionado, veja outros grupos',
              tips_2: 'Foi adicionado por outras empresas',
              tips_3: 'Este número não existe'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'Não passou',
              hasRefuse: 'rejeitado',
              hasPass: 'passado',
              hasInvalid: 'expirado',
              all: 'todos'
          }
      },
      button: {
          pass: 'passar',
          reject: 'rejeitar'
      },
      table: {
          th_number: 'Dispositivo Não',
          th_date: 'data',
          th_status: 'estado',
          th_source: 'fonte'
      },
      dialog: {
          passTitle: 'Aprovado',
          reject: {
              title: 'Negar revisão',
              tips: 'Tem certeza de que deseja rejeitar a avaliação?'
          }
      },
      api: {
          req: {
              tips_1: 'Incapaz de passar na revisão, os itens selecionados vêm de fontes diferentes!',
              tips_2: 'Selecione a região a ser alocada'
          },
          return: {
              tips_1: 'O número do dispositivo não existe',
              tips_2: 'Você ingressou na empresa',
              tips_3: 'Já adicionado, veja outros grupos'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'Por favor insira o conteúdo',
      table: {
          th: {
              roomId: 'número do quarto',
              state: 'estado do quarto',
              username: 'Criado porjid',
              createTime: 'Hora de criação da reunião',
              closeTime: 'horário de término da reunião',
              duration: 'Duração'
          }
      },
      dialog: {
          title: 'Registro de entrada/saída de membros da sala',
          table: {
              th: {
                  username: 'Criado porjid',
                  joinTime: 'Junte-se',
                  leaveTime: 'deixar',
                  duration: 'Duração'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'número de chamada',
              callTime: 'Hora de discagem',
              endTime: 'Fim do tempo',
              duration: 'Duração',
              responderMzNum: 'número chamado'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'Informações básicas do quarto',
              Attendees: 'participante',
              bystander: 'espectador',
              join: 'Para ser adicionado',
              exited: 'Saiu'
          }
      },
      roomNumber: 'Número da sala de conferência',
      name: 'Nome da sala de reunião',
      dissolution: 'Dissolver',
      pattern: 'modelo',
      confluence: 'confluência',
      forward: 'Avançar',
      createdTime: 'hora de criação',
      limit: 'Liberação/limite total de pessoas',
      createMode: 'criar padrão',
      order: 'Nomeação (reunião)',
      simple: 'Multijogador normal',
      type: 'Tipo de reunião',
      creator: 'O Criador',
      host: 'Hospedar:',
      windowMode: 'Modo janela:',
      WindowMode1: 'Um grande e muitos pequenos',
      WindowMode2: 'Telha',
      hide: 'Se deve ocultar:',
      forbidden: 'Se deve proibir o trigo:',
      inorder: 'ordem:',
      source: 'fonte:',
      joinTime: 'Tempo de adesão:',
      comeOut: 'Por favor, saia',
      openMic: 'ligue o microfone',
      closeMic: 'Desligue o microfone',
      leave: 'deixar:',
      peopleNumber: 'Número de pessoas:'
  },
  menu: {
      index: 'primeira página',
      device_manage: 'Gerenciamento de dispositivo',
      user_manage: 'Gerenciamento de usuários',
      conference_brand: 'Número da porta da conferência',
      audit_manage: 'Gestão de auditoria',
      orgMessage: 'Informação corporativa',
      schoolMessage: 'Informação corporativa',
      roomStatus: 'reunião em andamento',
      p2pCallLog: 'Histórico de videochamadas',
      conferenceLog: 'ata da reunião',
      authorizedMessage: 'Informações de autorização',
      title: 'Plataforma de gerenciamento empresarial',
      schooltitle: 'Plataforma de gerenciamento empresarial',
      account_mangae: 'Gerenciamento de contas',
      contacts: 'Catálogo de endereços de grupo',
      records: 'registros de chamadas',
      LicenseInformation: 'Informações de autorização',
      pushInfo: 'Envio de informações',
      infoGroup: 'Gerenciamento de grupo push'
  },
  download: 'baixar aplicativos',
  unauthorized: {
      title: 'Plataforma de gerenciamento empresarial ViiTALK (não autorizada)',
      span_1: 'Foi detectado que o seu servidor não autoriza o serviço ViiTALK,',
      span_2: 'Entre em contato com o atendimento ao cliente para obter o arquivo de autorização.',
      span_3: '(Para obter o arquivo de autorização, você precisa fornecer o endereço IP do servidor e o endereço Mac ao atendimento ao cliente)',
      upError: 'Desculpe, o arquivo que você enviou está errado. Faça upload novamente',
      chooseFile_b: 'Selecione um documento',
      upFile_b: 'Carregar arquivo de autorização',
      success: {
          span_1: 'Autorização bem-sucedida',
          span_2: 'Entrando na plataforma de gerenciamento empresarial...',
          span_3: '(Se você não entra há muito tempo, atualize o navegador)'
      },
      dialog: {
          title: 'Por favor altere a senha do administrador',
          span: 'Sua senha de administrador ainda não foi alterada, altere sua senha imediatamente',
          button: 'enviar'
      }
  },
  lang: {
    'zh-CN': 'Chinês simplificado',
    'zh-TW': 'chinês tradicional',
    en: 'Inglês',
    ja: 'japonês',
    ko: 'coreano',
    th: 'tailandês',
    ar: 'árabe',
    bn: 'bengali',
    de: 'Alemão',
    el: 'grego',
    es: 'espanhol',
    fa: 'persa',
    fr: 'Francês',
    id: 'indonésio',
    it: 'italiano',
    iw: 'hebraico',
    ku: 'curdo',
    nl: 'Holandês',
    pl: 'polonês',
    pt: 'Português',
    ro: 'romena',
    ru: 'russo',
    tr: 'turco',
    vi: 'vietnamita'
  },
  authorized: {
      org: 'Empresa autorizada',
      userLimit: 'Limite de usuários',
      roomMax: 'Capacidade máxima da sala de conferências na nuvem',
      roomCountLimit: 'Número máximo de salas de reuniões on-line na nuvem',
      authorizedActiveDate: 'Data de ativação da licença de nuvem privada',
      authorizedValidDate: 'A licença de nuvem privada é válida até',
      button_up: 'Atualizar autorização',
      upFileError: 'Nenhum arquivo selecionado'
  },
  index_title: 'Plataforma de gerenciamento empresarial ViiTalk',
  brand: {
    table: {
        th: {
            brandNumber: 'número da casa',
            bindNumber: 'Vincular número de tela grande',
            onlineStatus: 'Status online',
            lastLoginTime: 'última hora de login'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'on-line',
                    offline: 'desligada'
                }
            }
        }
    }
  }
}