import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: 'Italiano',
  serverError: 'Anomalia del server, contattare lamministratore',
  opearteSuccess: 'Operazione riuscita',
  agreement: {
      agreement_1: 'Ho letto e accettato',
      agreement_2: '\'Accordo per gli utenti\'',
      agreement_3: 'E',
      agreement_4: '\'Politica sulla riservatezza\'',
      agreement_5: 'Seleziona la casella per accettare laccordo'
  },
  login: {
      webName: 'Piattaforma di gestione aziendale ViiTALK',
      website: 'Sito ufficiale:',
      tel: 'Telefono:',
      email: 'Posta:',
      title: 'Accesso utente aziendale',
      inputPlaceholder: {
          name: 'Inserisci nome utente/e-mail',
          pwd: 'per favore inserisci LA TUA password'
      },
      button: {
          login: 'Login',
          email: 'E-mail di accesso',
          account: 'Account login',
          register: 'Registrazione utente aziendale',
          pwd: 'dimenticare la password'
      },
      error: {
          uPwd: 'nome utente o password errati',
          uLock: 'Laccount è stato bloccato',
          uLeave: 'Ti sei dimesso e non puoi utilizzare il sistema',
          uRelogin: 'Hai già effettuato laccesso da qualche altra parte!!!',
          uReplaceLogin: 'Il tuo account è stato registrato altrove e sei stato costretto a disconnetterti. Se non è stata colpa tua, la tua password potrebbe essere trapelata. Accedi di nuovo e modifica la password il prima possibile.',
          user_null: 'per favore inserisci il nome utente',
          pwd_null: 'Per favore, inserisci la password'
      }
  },
  personal: {
      companyNumber: 'Impresa',
      administrators: 'amministratore',
      changeLanguage: 'cambiare lingua',
      logout: 'disconnessione'
  },
  companyMessage: {
      adminPwd: 'Password amministratore',
      adminUserName: 'Nome dell amministratore',
      adminEmail: 'E-mail dell amministratore',
      companyFullName: 'Nome completo dellazienda (denominazione legalmente registrata)',
      companySimpleNameCn: 'Abbreviazione della società',
      companySimpleNameEn: 'Abbreviazione aziendale (inglese)',
      companyWebsite: 'Sito web aziendale',
      companyNumber: 'Numero del gruppo aziendale',
      companyNoAuditJoin: 'Partecipa senza revisione',
      adminExplain: 'Il nome dell amministratore è visibile a tutti i membri della rubrica aziendale',
      companySimpleExplain: 'La sigla aziendale verrà visualizzata nella home page del dispositivo Rooms e visibile a tutti gli iscritti alla rubrica aziendale.',
      button: {
          Edit: 'Rivedere'
      },
      dialog: {
          editpwd: {
              title: 'Cambia password amministratore',
              label: {
                  oldPwd: 'vecchia password',
                  newPwd: 'nuova password',
                  reNewPwd: 'Conferma la nuova password'
              },
              inputPlaceholder: {
                  oldPwd: 'Inserisci la vecchia password',
                  newPwd: 'Inserisci una nuova password',
                  reNewPwd: 'Inserisci per confermare la nuova password'
              }
          },
          editName: {
              title: 'Modifica il nome dell amministratore',
              label: {
                  name: 'nuovo nome'
              },
              inputPlaceholder: {
                  name: 'Per favore inserisci il nuovo nome'
              }
          },
          editEmail: {
              title: 'Modifica le-mail dell amministratore',
              title2: 'Non hai ancora vincolato il tuo indirizzo email. Per favore vincola il tuo indirizzo email in tempo.',
              label: {
                  email: 'nuova casella di posta'
              },
              inputPlaceholder: {
                  email: 'Inserisci una nuova email'
              }
          },
          editCompanyFullName: {
              title: 'Modificare il nome completo dell azienda',
              label: {
                  name: 'nuovo nome completo'
              },
              inputPlaceholder: {
                  name: 'Inserisci il nuovo nome completo'
              }
          },
          editCompanyAbbreviationCn: {
              title: 'Modifica sigla azienda (cinese)',
              label: {
                  name: 'Nuova abbreviazione'
              },
              inputPlaceholder: {
                  name: 'Inserisci un nuovo nome breve'
              }
          },
          editCompanyAbbreviationEn: {
              title: 'Modifica sigla azienda (inglese)'
          },
          editWebsite: {
              title: 'Modificare l indirizzo del sito web aziendale',
              label: {
                  website: 'Nuovo URL'
              },
              inputPlaceholder: {
                  website: 'Inserisci il nuovo URL'
              }
          },
          editNoAuditJoin: {
              title: 'Modificare l opzione di adesione senza applicazioni',
              radio: {
                  open: 'Aprire',
                  close: 'chiusura'
              }
          }
      },
      validator: {
          value_null: 'Il valore non può essere vuoto',
          oldPwd: {
              null: 'Inserisci la vecchia password'
          },
          newPwd: {
              length: 'Imposta una password composta da 6 caratteri o più'
          },
          reNewPwd: {
              consistent: 'Le password inserite due volte non sono coerenti!'
          },
          name: {
              length: 'Il nome non può contenere più di 20 caratteri'
          },
          email: {
              rule: 'Il formato dell e-mail non è corretto'
          },
          NoAuditJoin: {
              null: 'Seleziona un elemento'
          }
      }
  },
  register: {
      step_1: 'nome utente e password',
      step_2: 'Compila le informazioni aziendali',
      step_3: 'Fine',
      head: 'Registrazione utente aziendale',
      usernameError: 'Il nome del membro deve contenere da 6 a 20 caratteri, esclusi i caratteri cinesi, e iniziare con una lettera.',
      usernameError_exist: 'Il nome utente esiste già, modifica il tuo nome utente',
      emailError: 'Il formato dell e-mail non è corretto',
      emailError_exist: 'Email già esistente',
      passwordError_length: '6-20 caratteri, solo segni alfanumerici e di punteggiatura',
      passwordError_same: 'La password non può essere lo stesso nome utente',
      passwordError: 'Il formato della password non è corretto',
      rePasswordError: 'Le password inserite due volte non sono coerenti, reinserirle',
      companyNameError: 'Inserisci il nome completo dell azienda',
      companyAbbreviationError: 'Inserisci la sigla dell azienda',
      orgError_exist:'L azienda è stata registrata, si prega di cambiare il nome dell attività',
      button: {
          next: 'Passo successivo',
          login: 'accedi immediatamente'
      },
      inputPlaceholder: {
          username: 'nome utente',
          email: 'Posta',
          password: 'impostare la password',
          rePassword: 'Immettere nuovamente la password',
          name: 'Nome completo dell azienda (denominazione legalmente registrata)*',
          simpleNameCN: 'Sigla aziendale (cinese)*',
          simpleNameEN: 'Abbreviazione aziendale (inglese)',
          website: 'Sito aziendale (www)'
      },
      success: 'Hai creato con successo un account aziendale!',
      location: 'posizione:',
      Scale: 'scala:'
  },
  forgotPwd: {
      head: 'Recupero e-mail',
      resetSuccess: 'Hai reimpostato con successo la tua password!',
      inputPlaceholder: {
          code: 'inserisci il codice di verifica'
      },
      sendCodeError: 'Codice di verifica errato',
      sendCodeError_sended: 'Il codice di verifica è stato inviato, controllalo',
      button: {
          getCode: 'ottenere il codice di verifica',
          reGetCode: 'Ottieni di nuovo il codice di verifica'
      }
  },
  button: {
      ok: 'Confermare',
      cancel: 'Annulla',
      search: 'ricerca',
      clear: 'Chiaro'
  },
  device: {
      button: {
          setContent: 'Configura la pianificazione push',
          setGroupMng: 'Configura l amministratore del gruppo',
          startNow: 'Spingi adesso',
          stopNow: 'Smettila di spingere',
          add: 'Aggiungi dispositivo',
          search: 'ricerca',
          adjust: 'Regola il raggruppamento',
          del: 'eliminare'
      },
      inputPlaceholder: {
          search: 'Numero del dispositivo/Numero di cellulare/Numero ViiTALK'
      },
      group: {
          add: 'Aggiungere gruppo',
          edit: 'Modifica gruppo',
          del: 'Elimina gruppo'
      },
      table: {
          th_platform: 'Platform',
          th_ver: 'APP Ver',
          th_public: 'Version Type',
          th_name: 'Nome del dispositivo',
          th_number: 'Numero del dispositivo',
          th_time: 'Ora dell ultimo accesso',
          th_brandNumber: 'Numero civico della conferenza',
          th_online: 'È in linea',
          th_status: 'stato',
          th_bindNumber: 'Associa un numero di schermo grande'
      },
      dialog: {
          addGroupTitle: 'Inserisci il nome del gruppo',
          inputPlaceholder: {
              groupName: 'Inserisci il nome del gruppo'
          },
          delGroupTitle: 'Elimina gruppo',
          delGroupTips: 'Sei sicuro di voler eliminare il gruppo?',
          editGroupTitle: 'Modifica gruppo',
          groupMng: {
              title: 'Modifica gestore del gruppo',
              inputPlaceholder: {
                  name: 'Inserisci il nome dell amministratore'
              },
              label: {
                  name: 'Nome dell amministratore'
              }
          },
          addDevice: {
              title: 'Aggiungi dispositivo',
              inputPlaceholder: {
                  name: 'Inserisci il nome del dispositivo',
                  number: 'Inserisci il numero del dispositivo'
              },
              label: {
                  name: 'Nome del dispositivo',
                  number: 'Numero del dispositivo',
                  group: 'Seleziona un gruppo di dispositivi'
              }
          },
          delDevice: {
              title: 'Rimuovi il dispositivo',
              tips: 'Sei sicuro di voler eliminare il dispositivo?'
          },
          startNowDevice: {
              title: 'Spingi adesso',
              tips: 'Sei sicuro di volerlo spingere adesso?'
          },
          stopNowDevice: {
              title: 'Smettila di spingere',
              tips: 'Sei sicuro di voler smettere di spingere?'
          },
          adjustTitle: 'Regola il raggruppamento',
          editDevice: {
              title: 'Modifica il nome del dispositivo',
              inputPlaceholder: {
                  name: 'Inserisci il nome del dispositivo'
              }
          },
          noChildren: {
              title: 'avvisare',
              tips: 'Aggiungi prima un gruppo'
          }
      },
      validator: {
          group: {
              name_null: 'Il nome del gruppo non può essere vuoto',
              name_length: 'Non può superare i 10 caratteri'
          },
          number_null: 'Il numero non può essere vuoto',
          number_rule: 'Possono contenere solo 10 cifre che iniziano con 6 o 8',
          name_null: 'Il nome del terminale non può essere vuoto'
      }
  },
  user: {
      button: {
          add: 'Aggiungi utente'
      },
      inputPlaceholder: {
          search: 'Numero ViiTALK'
      },
      table: {
          th_name: 'Nome',
          th_number: 'Numero di cellulare/Numero di Maizhe'
      },
      dialog: {
          addUser: {
              title: 'Aggiungi utente',
              inputPlaceholder: {
                  number: 'Inserisci il numero ViiTALK'
              },
              label: {
                  number: 'Numero ViiTALK',
                  group: 'Seleziona il gruppo utenti'
              }
          },
          delUser: {
              title: 'eliminare gli utenti',
              tips: 'Sei sicuro di voler eliminare l utente?'
          }
      },
      validator: {
          number_null: 'Il numero non può essere vuoto',
          number_rule: 'Questo numero non esiste'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: 'Il gruppo da eliminare non esiste',
              tips_2: 'Nel gruppo da eliminare sono presenti sottogruppi che non possono essere eliminati.',
              tips_3: 'Ci sono persone nel gruppo da eliminare e non possono essere eliminate.'
          },
          addDevice: {
              tips_1: 'Già aggiunto, visualizza altri gruppi',
              tips_2: 'È stato aggiunto da altre società',
              tips_3: 'Questo numero non esiste'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: 'Non è passato',
              hasRefuse: 'respinto',
              hasPass: 'passato',
              hasInvalid: 'scaduto',
              all: 'Tutto'
          }
      },
      button: {
          pass: 'passaggio',
          reject: 'rifiutare'
      },
      table: {
          th_number: 'Dispositivo n',
          th_date: 'data',
          th_status: 'stato',
          th_source: 'fonte'
      },
      dialog: {
          passTitle: 'Approvato',
          reject: {
              title: 'Nega la revisione',
              tips: 'Sei sicuro di voler rifiutare la recensione?'
          }
      },
      api: {
          req: {
              tips_1: 'Impossibile superare la revisione, gli articoli selezionati provengono da fonti diverse!',
              tips_2: 'Seleziona la regione da assegnare'
          },
          return: {
              tips_1: 'Il numero del dispositivo non esiste',
              tips_2: 'Ti sei unito all impresa',
              tips_3: 'Già aggiunto, visualizza altri gruppi'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: 'Inserisci il contenuto',
      table: {
          th: {
              roomId: 'numero di Camera',
              state: 'stato della camera',
              username: 'Creato dajid',
              createTime: 'Ora di creazione della riunione',
              closeTime: 'ora di fine della riunione',
              duration: 'Durata'
          }
      },
      dialog: {
          title: 'Registrazione dei membri che entrano/escono dalla stanza',
          table: {
              th: {
                  username: 'Creato dajid',
                  joinTime: 'partecipare',
                  leaveTime: 'Partire',
                  duration: 'Durata'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: 'numero chiamante',
              callTime: 'Quadrante dell ora',
              endTime: 'Tempo scaduto',
              duration: 'Durata',
              responderMzNum: 'numero chiamato'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: 'Informazioni di base sulla camera',
              Attendees: 'Partecipante',
              bystander: 'spettatore',
              join: 'Essere aggiunto',
              exited: 'Uscito'
          }
      },
      roomNumber: 'Numero della sala conferenze',
      name: 'Nome della sala riunioni',
      dissolution: 'Sciogliersi',
      pattern: 'modello',
      confluence: 'confluenza',
      forward: 'Inoltrare',
      createdTime: 'tempo di creazione',
      limit: 'Limite di rilascio/numero totale di persone',
      createMode: 'creare un modello',
      order: 'Appuntamento (riunione)',
      simple: 'Multigiocatore ordinario',
      type: 'Tipo di riunione',
      creator: 'Creatore',
      host: 'Ospite:',
      windowMode: 'Modalità finestra:',
      WindowMode1: 'Uno grande e tanti piccoli',
      WindowMode2: 'Piastrella',
      hide: 'Se nascondere:',
      forbidden: 'Se vietare il grano:',
      inorder: 'ordine:',
      source: 'fonte:',
      joinTime: 'Orario di partecipazione:',
      comeOut: 'Per favore, vieni fuori',
      openMic: 'accendere il microfono',
      closeMic: 'Spegni il microfono',
      leave: 'Partire:',
      peopleNumber: 'Numero di persone:'
  },
  menu: {
      index: 'prima pagina',
      device_manage: 'Gestione dei dispositivi',
      user_manage: 'Gestione utenti',
      conference_brand: 'Numero della porta della conferenza',
      audit_manage: 'Gestione dell audit',
      orgMessage: 'Informazioni aziendali',
      roomStatus: 'incontro in corso',
      p2pCallLog: 'Cronologia delle videochiamate',
      conferenceLog: 'verbali delle riunioni',
      authorizedMessage: 'Informazioni sull autorizzazione',
      title: 'Piattaforma di gestione aziendale',
      account_mangae: 'Gestione contabile',
      contacts: 'Rubrica di gruppo',
      records: 'registri delle chiamate',
      LicenseInformation: 'Informazioni sull autorizzazione',
      pushInfo: 'Spinta di informazioni',
      infoGroup: 'Gestione gruppi push'
  },
  download: 'scaricare app',
  unauthorized: {
      title: 'Piattaforma di gestione aziendale ViiTALK (non autorizzata)',
      span_1: 'È stato rilevato che il tuo server non autorizza il servizio ViiTALK,',
      span_2: 'Si prega di contattare il servizio clienti per ottenere il file di autorizzazione.',
      span_3: '(Per ottenere il file di autorizzazione, è necessario fornire l indirizzo IP del server e l indirizzo Mac al servizio clienti)',
      upError: 'Siamo spiacenti, il file che hai caricato è sbagliato, caricalo di nuovo',
      chooseFile_b: 'Seleziona un documento',
      upFile_b: 'Carica il file di autorizzazione',
      success: {
          span_1: 'Autorizzazione riuscita',
          span_2: 'Accesso alla piattaforma di gestione aziendale...',
          span_3: '(Se non entri da molto tempo aggiorna il browser)'
      },
      dialog: {
          title: 'Si prega di modificare la password dell amministratore',
          span: 'La tua password di amministratore non è stata ancora modificata, cambiala immediatamente',
          button: 'invia'
      }
  },
  lang: {
    'zh-CN': 'Cinese semplificato',
    'zh-TW': 'cinese tradizionale',
    en: 'Inglese',
    ja: 'giapponese',
    ko: 'coreano',
    th: 'tailandese',
    ar: 'Arabo',
    bn: 'bengalese',
    de: 'Tedesco',
    el: 'greco',
    es: 'spagnolo',
    fa: 'persiano',
    fr: 'francese',
    id: 'indonesiano',
    it: 'Italiano',
    iw: 'ebraico',
    ku: 'curdo',
    nl: 'Olandese',
    pl: 'Polacco',
    pt: 'portoghese',
    ro: 'rumeno',
    ru: 'russo',
    tr: 'Turco',
    vi: 'vietnamita'
  },
  authorized: {
      org: 'Impresa autorizzata',
      userLimit: 'Limite utente',
      roomMax: 'Capacità massima della sala conferenze cloud',
      roomCountLimit: 'Numero massimo di sale riunioni cloud online',
      authorizedActiveDate: 'Data di attivazione della licenza per cloud privato',
      authorizedValidDate: 'La licenza del cloud privato è valida fino al',
      button_up: 'Aggiorna autorizzazione',
      upFileError: 'Nessun file selezionato'
  },
  index_title: 'Piattaforma di gestione aziendale ViiTalk',
  brand: {
    table: {
        th: {
            brandNumber: 'numero di casa',
            bindNumber: 'Associare il numero di schermata',
            onlineStatus: 'stato online',
            lastLoginTime: 'ora dell ultimo accesso'
        },
        col: {
            content: {
                onlineStatus: {
                    online: 'in linea',
                    offline: 'disconnesso'
                }
            }
        }
    }
  }
}