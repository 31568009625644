<!-- eslint-disable no-undef -->

<template>
  <div>
    <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary"
      :class="addDeviceButtonClass" @click="openAddDeviceDialog">
      {{ $t('device.button.add') }}
    </el-button>

    <el-divider class="divider"></el-divider>

    <div class="flex-container">
      <el-row class="flex-container">
        <el-col :span="9">
          <el-card class="left-card card-format">
            <div class="input-group">
              <el-input :class="searchInputplaceholder" v-model="searchKeyword"
                :placeholder="$t('device.inputPlaceholder.search')">
              </el-input>
              <el-button style="width: auto; padding-left: 15px; padding-right: 15px;"
                class="opearte-button opearte-font opearte-border" @click="searchMember">
                {{ $t('device.button.search') }}
              </el-button>
            </div>
            <div class="tree-container">

              <el-tree :data="treeData" node-key="id" :props="defaultProps" @node-click="handleNodeClick"
                :expand-on-click-node="false" draggable :allow-drop="allowDrop" @node-drop="handleDrop"
                :default-expanded-keys="defaultExpandedKeys" ref="tree" :highlight-current="true">

                <span :class="data.parentId == 0 ? 'custom-tree-node tree-root-font' : 'custom-tree-node'"
                  slot-scope="{ node, data }" @mouseenter="mouseenter(data)" @mouseleave="mouseleave(data)">
                  <span>{{ node.label }}</span>

                  <div style="display: flex; align-items: center; justify-content: space-between;">

                    <el-dropdown trigger="click" v-show="data.show" @click.native="handleCommand(data)"
                      @visible-change="handleVisibleChange" style="margin-top: 3px;">
                      <span class="el-dropdown-link">
                        <img src="../../assets/image/treeNodeOpearte.png">
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-show="data.parentId == 0 ? true : false"
                          @click.native="openAddGroupDialog(data)">{{ $t('device.group.add') }}</el-dropdown-item>
                        <el-dropdown-item v-show="data.parentId == 0 ? false : true"
                          @click.native="openEditGroupDialog(data)">{{ $t('device.group.edit') }}</el-dropdown-item>
                        <el-dropdown-item v-show="data.parentId == 0 ? false : true"
                          @click.native="openDelGroupDialog(data)">{{ $t('device.group.del') }}</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>

                    <span class="el-icon-share" v-show="data.show"></span>
                  </div>

                </span>
              </el-tree>

            </div>
          </el-card>
        </el-col>

        <el-col :span="12">
          <el-card class="right-card card-format">

            <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" :class="adjustButtonClass"
              @click="openAdjustDeviceGroupDialog" :disabled=isButtonDisabled>
              {{ $t('device.button.adjust') }}
            </el-button>

            <el-button style="width: auto; padding-left: 15px; padding-right: 15px;"
              class="opearte-button opearte-font opearte-border" @click="openDelDeviceDialog"
              :disabled=isButtonDisabled>
              {{ $t('device.button.del') }}
            </el-button>

            <el-table :data="tableData" class="table-col-font"
              :header-cell-style="{ background: '#FFFFFF', color: '#000000' }"
              @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55" header-align="center" align="center"></el-table-column>
              <el-table-column prop="nickName" :label="$t('device.table.th_name')" width="220"></el-table-column>
              <el-table-column prop="mzNumber" :label="$t('device.table.th_number')" width="180" header-align="center"
                align="center"></el-table-column>
              <el-table-column prop="lastTime" :label="$t('device.table.th_time')" header-align="center" align="center"
                width="200">
                <template slot-scope="scope">
                  {{ formatDateTime(scope.row.lastTime) }}
                </template>
              </el-table-column>
              <el-table-column prop="devModel" :label="$t('device.table.th_platform')" width="120"></el-table-column>
              <el-table-column prop="avatarID" :label="$t('device.table.th_ver')" width="100"></el-table-column>
              <el-table-column :label="$t('device.table.th_public')" width="120">Public</el-table-column>

              <el-table-column>
                <template slot-scope="scope">
                  <img @click="openEditDeviceNameDialog(scope.row)" class="table-img "
                    src="../../assets/image/edit.png">
                </template>
              </el-table-column>
            </el-table>

            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="pageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="dataCount" class="pagination-right-aligned">
            </el-pagination>

          </el-card>
        </el-col>
      </el-row>

    </div>

    <el-dialog :title="$t('device.dialog.addGroupTitle')" :visible.sync="addGroupDialogVisible" width="360px"
      @close="closeAddGroupDialog" center>

      <el-form :model="addGroupForm" status-icon :rules="addGroupRules" ref="addGroupForm" class="demo-ruleForm">
        <el-form-item prop="name">
          <el-input :placeholder="$t('device.dialog.inputPlaceholder.groupName')" v-model="addGroupForm.name"
            autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeAddGroupDialog"> {{
        $t('button.cancel') }}</el-button>
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="addGroup"> {{
        $t('button.ok') }}</el-button>
      </span>

    </el-dialog>

    <el-dialog :title="$t('device.dialog.delGroupTitle')" :visible.sync="delGroupDialogVisible" width="360px" center>

      <span>{{ $t('device.dialog.delGroupTips') }}</span>

      <span slot="footer" class="dialog-footer">
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;"
          @click="delGroupDialogVisible = false">{{
        $t('button.cancel') }}</el-button>
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="delGroup">{{
        $t('button.ok') }}</el-button>
      </span>

    </el-dialog>

    <el-dialog :title="$t('device.dialog.editGroupTitle')" :visible.sync="editGroupDialogVisible" width="360px"
      @close="closeEditGroupDialog" center>

      <el-form :model="editGroupForm" status-icon :rules="addGroupRules" ref="editGroupForm" class="demo-ruleForm">
        <el-form-item prop="name">
          <el-input v-model="editGroupForm.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeEditGroupDialog">{{
        $t('button.cancel') }}</el-button>
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="editGroup">{{
        $t('button.ok') }}</el-button>
      </span>

    </el-dialog>

    <el-dialog :title="$t('device.dialog.addDevice.title')" :visible.sync="addDeviceDialogVisible" width="360px"
      @close="closeAddDeviceDialog" center>

      <el-form :model="addDeviceForm" status-icon :rules="addDeviceRules" ref="addDeviceForm" label-position="top">

        <el-form-item :label="$t('device.dialog.addDevice.label.number')" prop="number">
          <el-input v-model="addDeviceForm.number" :placeholder="$t('device.dialog.addDevice.inputPlaceholder.number')"
            autocomplete="off" @keyup.native="trimLR('addDeviceForm', 'number')"
            @blur="addDeviceNumberComplete"></el-input>
        </el-form-item>

        <el-form-item :label="$t('device.dialog.addDevice.label.name')" prop="name">
          <el-input v-model="addDeviceForm.name" :placeholder="$t('device.dialog.addDevice.inputPlaceholder.name')"
            autocomplete="off" @input="handleChangeDataForm($event)"></el-input>
        </el-form-item>

        <el-form-item :label="$t('device.dialog.addDevice.label.group')">
          <el-select v-model="addDeviceForm.groupName" ref="selectUpResId">
            <el-option style="height: auto">
              <el-tree :data="filteredTreeData" node-key="id" :props="defaultProps" @node-click="addDeviceTreeNodeCilck"
                :expand-on-click-node="false" :check-on-click-node="true">
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span>{{ node.label }}</span>
                </span>
              </el-tree>
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeAddDeviceDialog"> {{
        $t('button.cancel') }} </el-button>
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="addDevice">{{
        $t('button.ok') }}</el-button>
      </span>

    </el-dialog>

    <el-dialog :title="$t('device.dialog.delDevice.title')" :visible.sync="delDeviceDialogVisible" width="360px"
      @close="closeDelGroupDialog" center>

      <span>{{ $t('device.dialog.delDevice.tips') }}</span>

      <span slot="footer" class="dialog-footer">
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;"
          @click="delDeviceDialogVisible = false"> {{
        $t('button.cancel') }}</el-button>
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="delDevice">{{
        $t('button.ok') }}</el-button>
      </span>

    </el-dialog>

    <el-dialog :title="$t('device.dialog.adjustTitle')" :visible.sync="adjustDeviceGroupDialogVisible" width="360px"
      @close="closeDelGroupDialog" center>

      <el-tree :data="filteredTreeData" node-key="id" :props="defaultProps" @node-click="adjustDeviceGroupTreeNodeCilck"
        :expand-on-click-node="false" :check-on-click-node="true">
        <span class="custom-tree-node" slot-scope="{ node }">
          <span>{{ node.label }}</span>
        </span>
      </el-tree>

      <span slot="footer" class="dialog-footer">
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;"
          @click="adjustDeviceGroupDialogVisible = false">{{ $t('button.cancel') }}</el-button>
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary"
          @click="adjustDeviceGroup">{{ $t('button.ok') }}</el-button>
      </span>

    </el-dialog>

    <el-dialog :title="$t('device.dialog.editDevice.title')" :visible.sync="editDeviceNameDialogVisible" width="360px"
      @close="closeEditDeviceNameDialog" center>

      <el-form :model="editDeviceForm" status-icon :rules="editDeviceRules" ref="editDeviceForm" class="demo-ruleForm">
        <el-form-item prop="name">
          <el-input :placeholder="$t('device.dialog.editDevice.inputPlaceholder.name')" v-model="editDeviceForm.name"
            autocomplete="off" @input="handleChangeDataForm($event)"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" @click="closeEditDeviceNameDialog">{{
        $t('button.cancel') }}</el-button>
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary"
          @click="editDeviceName">{{
        $t('button.ok') }}</el-button>
      </span>

    </el-dialog>

    <el-dialog :title="$t('device.dialog.noChildren.title')" :visible.sync="noChildrenDialogVisible" width="360px"
      @close="closeNoChildrenDialog" center>

      <span>{{ $t('device.dialog.noChildren.tips') }}</span>

      <span slot="footer" class="dialog-footer">
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;"
          @click="noChildrenDialogVisible = false">
          {{ $t('button.cancel') }}</el-button>
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary"
          @click="closeNoChildrenDialog">{{ $t('button.ok') }}</el-button>
      </span>

    </el-dialog>

  </div>
</template>

<script>
import { addrbook } from '../js/addrbook.js'
export default {
  mixins: [addrbook]
}
</script>

<style src="../../assets/css/dialog.css" scoped></style>

<style src="../../assets/css/tree.css" scoped></style>

<style src="../../assets/css/addrbook.css" scoped></style>
