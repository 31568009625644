<template>
  <el-card class="table-card card-format">
    <div class="table-header">

      <div>
        <span>
          {{ $t('audit.table.th_status') }}:
        </span>
        <el-select v-model="auditStatus" @change="handleSelectChange">
          <el-option :label="$t('audit.option.label.NoPass')" value="0"></el-option>
          <el-option :label="$t('audit.option.label.hasRefuse')" value="1"></el-option>
          <el-option :label="$t('audit.option.label.hasPass')" value="2"></el-option>
          <el-option :label="$t('audit.option.label.hasInvalid')" value="3"></el-option>
          <el-option :label="$t('audit.option.label.all')" value="4"></el-option>
        </el-select>
      </div>

      <div class="buttons-container">
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="handleApprove" :disabled="tableSelection.length === 0">{{
          $t('audit.button.pass') }}</el-button>
        <el-button style="width: auto; padding-left: 15px; padding-right: 15px;" type="primary" @click="handleReject" :disabled="tableSelection.length === 0">{{
          $t('audit.button.reject') }}</el-button>
      </div>

    </div>

    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
      @selection-change="handleSelectionChange" :header-cell-style="{ background: '#FFFFFF', color: '#000000' }"
      class="table-col-font" :row-style="readerFun">
      <el-table-column type="selection" width="55" :selectable="row => row.auditStatus === 0">
      </el-table-column>
      <el-table-column prop="auditNumber" :label="$t('audit.table.th_number')" width="200">
      </el-table-column>
      <el-table-column :label="$t('audit.table.th_date')" prop="auditDate" width="220">
      </el-table-column>
      <el-table-column prop="auditStatus" :label="$t('audit.table.th_status')">
        <template slot-scope="scope">{{ formatStatus(scope.row.auditStatus) }}</template>
      </el-table-column>
      <el-table-column prop="auditSource" :label="$t('audit.table.th_source')">

      </el-table-column>
    </el-table>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex"
      :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
      :total="dataCount" class="pagination-right-aligned">
    </el-pagination>

    <el-dialog :title="$t('audit.dialog.passTitle')" :visible.sync="passAuditDialogVisible" width="360px"
      @close="closePassAuditDialog" center>

      <el-tree :data="treeData" node-key="id" :props="defaultProps" @node-click="treeNodeCilck"
        :expand-on-click-node="false" :check-on-click-node="true">
        <span class="custom-tree-node" slot-scope="{ node }">
          <span>{{ node.label }}</span>
        </span>
      </el-tree>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closePassAuditDialog">{{ $t('button.cancel') }}</el-button>
        <el-button type="primary" @click="passAudit">{{ $t('button.ok') }}</el-button>
      </span>

    </el-dialog>

    <el-dialog :title="$t('audit.dialog.reject.title')" :visible.sync="refuseAuditDialogVisible" width="360px" center>

      <span>{{ $t('audit.dialog.reject.tips') }}</span>

      <span slot="footer" class="dialog-footer">
        <el-button @click="refuseAuditDialogVisible = false">{{ $t('button.cancel') }}</el-button>
        <el-button type="primary" @click="refuseAudit">{{ $t('button.ok') }}</el-button>
      </span>

    </el-dialog>

  </el-card>
</template>

<script>
import { audit } from '../js/audit.js'
export default {
  mixins: [audit]
}
</script>
<style src="../../assets/css/tree.css" scoped></style>
<style src="../../assets/css/audit.css" scoped></style>
<style src="../../assets/css/dialog.css" scoped></style>
