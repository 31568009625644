import InfoGroup from "@/components/pushInfo/infoGroup.vue";

export default {
  language: '한국인',
  serverError: '서버에 이상이 있으니 관리자에게 문의하세요',
  opearteSuccess: '성공적인 운영',
  agreement: {
      agreement_1: '읽고 동의했습니다',
      agreement_2: '\'이용약관\'',
      agreement_3: '그리고',
      agreement_4: '\'개인 정보 정책\'',
      agreement_5: '계약에 동의하려면 확인란을 선택하세요.'
  },
  login: {
      webName: 'ViiTALK 기업 관리 플랫폼',
      webNameSchool: 'ViiTALK 기업 관리 플랫폼',
      website: '공식 웹 사이트:',
      tel: '전화:',
      email: '우편:',
      title: '기업 사용자 로그인',
      titleSchool: '기업 사용자 로그인',
      inputPlaceholder: {
          name: '사용자 이름/이메일을 입력하세요.',
          pwd: '비밀번호를 입력해주세요'
      },
      button: {
          login: '로그인',
          email: '이메일 로그인',
          account: '계정 로그인',
          register: '기업 사용자 등록',
          registerSchool: '기업 사용자 등록',
          pwd: '비밀번호를 잊어버려'
      },
      error: {
          uPwd: '잘못된 사용자 이름 또는 비밀번호',
          uLock: '계정이 잠겼습니다',
          uLeave: '탈퇴하여 시스템을 사용할 수 없습니다.',
          uRelogin: '이미 다른 곳에 로그인되어 있습니다!!!',
          uReplaceLogin: '귀하의 계정은 다른 곳에 로그인되어 있으며 강제로 로그아웃되었습니다. 본인의 잘못이 아니었다면 비밀번호가 유출되었을 가능성이 있으니, 빠른 시일 내에 다시 로그인하여 비밀번호를 변경하시기 바랍니다.',
          user_null: '사용자 이름을 입력하세요',
          pwd_null: '비밀번호를 입력 해주세요'
      }
  },
  personal: {
      companyNumber: '기업',
      schoolNumber: '기업',
      administrators: '관리자',
      changeLanguage: '언어 전환',
      logout: '로그아웃'
  },
  companyMessage: {
      adminPwd: '관리자 비밀번호',
      adminUserName: '관리자 이름',
      adminEmail: '관리자 이메일',
      companyFullName: '회사 이름(법적으로 등록된 이름)',
      companySimpleNameCn: '회사 약어',
      companySimpleNameEn: '회사 약어(영문)',
      companyWebsite: '기업 웹사이트',
      companyNumber: '엔터프라이즈 그룹 번호',

      schoolFullName: '회사 이름(법적으로 등록된 이름)',
      schoolSimpleNameCn: '회사 약어',
      schoolSimpleNameEn: '회사 약어(영문)',
      schoolWebsite: '기업 웹사이트',
      schoolNumber: '엔터프라이즈 그룹 번호',

      companyNoAuditJoin: '검토 없이 가입',
      adminExplain: '관리자 이름은 모든 회사 주소록 구성원에게 표시됩니다.',
      companySimpleExplain: '회사 약어는 회의실 장치 홈페이지에 표시되며 회사 주소록의 모든 구성원에게 표시됩니다.',
      button: {
          Edit: '개정하다'
      },
      dialog: {
          editpwd: {
              title: '관리자 비밀번호 변경',
              label: {
                  oldPwd: '기존 비밀번호',
                  newPwd: '새 비밀번호',
                  reNewPwd: '새 비밀번호를 확인하세요'
              },
              inputPlaceholder: {
                  oldPwd: '이전 비밀번호를 입력해주세요',
                  newPwd: '새로운 비밀번호를 입력해주세요',
                  reNewPwd: '새 비밀번호를 확인하려면 입력하세요.'
              }
          },
          editName: {
              title: '관리자 이름 수정',
              label: {
                  name: '새 이름'
              },
              inputPlaceholder: {
                  name: '새 이름을 입력하세요.'
              }
          },
          editEmail: {
              title: '관리자 이메일 수정',
              title2: '아직 이메일 주소를 연결하지 않았습니다. 이메일 주소를 제때에 연결해 주세요.',
              label: {
                  email: '새 우편함'
              },
              inputPlaceholder: {
                  email: '새 이메일을 입력하세요.'
              }
          },
          editCompanyFullName: {
              title: '회사의 전체 이름을 수정하세요.',
              label: {
                  name: '새로운 이름'
              },
              inputPlaceholder: {
                  name: '새 이름을 입력하세요.'
              }
          },
          editCompanyAbbreviationCn: {
              title: '회사 약어 수정(중국어)',
              label: {
                  name: '새로운 약어'
              },
              inputPlaceholder: {
                  name: '새로운 닉네임을 입력하세요.'
              }
          },
          editCompanyAbbreviationEn: {
              title: '회사 약어 수정(영문)'
          },
          editWebsite: {
              title: '회사 웹사이트 주소 수정',
              label: {
                  website: '새 URL'
              },
              inputPlaceholder: {
                  website: '새 URL을 입력하세요.'
              }
          },
          editNoAuditJoin: {
              title: '신청 없는 가입 스위치 수정',
              radio: {
                  open: '열려 있는',
                  close: '폐쇄'
              }
          }
      },
      validator: {
          value_null: '값은 비워둘 수 없습니다.',
          oldPwd: {
              null: '이전 비밀번호를 입력해주세요'
          },
          newPwd: {
              length: '비밀번호는 6자 이상으로 설정해주세요'
          },
          reNewPwd: {
              consistent: '두 번 입력한 비밀번호가 일치하지 않습니다!'
          },
          name: {
              length: '이름은 20자를 초과할 수 없습니다.'
          },
          email: {
              rule: '이메일 형식이 올바르지 않습니다.'
          },
          NoAuditJoin: {
              null: '항목을 선택해주세요'
          }
      }
  },
  register: {
      step_1: '사용자 암호',
      step_2: '정보',
      step_3: '마치다',
      head: '기업 사용자 등록',
      usernameError: '회원 이름은 6~20자 이내여야 하며, 한자를 제외하고 문자로 시작해야 합니다.',
      usernameError_exist: '사용자 이름이 이미 존재합니다. 사용자 이름을 수정하십시오.',
      emailError: '이메일 형식이 올바르지 않습니다.',
      emailError_exist: '이메일이 이미 존재합니다',
      passwordError_length: '6~20자(영숫자 및 구두점만 사용)',
      passwordError_same: '비밀번호는 동일한 사용자 이름일 수 없습니다.',
      passwordError: '비밀번호 형식이 잘못되었습니다.',
      rePasswordError: '두 번 입력한 비밀번호가 일치하지 않습니다.',
      companyNameError: '회사의 전체 이름을 입력하세요.',
      companyAbbreviationError: '회사 약자를 입력하세요.',
      orgError_exist:'회사가 등록되었습니다, 상호를 변경해 주십시오.',
      button: {
          next: '다음 단계',
          login: '즉시 로그인'
      },
      inputPlaceholder: {
          username: '사용자 이름',
          email: '우편',
          password: '암호를 설정하세요',
          rePassword: '비밀번호를 다시 입력하세요',
          name: '회사 이름(법적으로 등록된 이름)*',
          simpleNameCN: '회사 약어(중국어)*',
          simpleNameEN: '회사 약어(영문)',
          website: '회사 홈페이지(www)'
      },
      success: '비즈니스 계정이 성공적으로 생성되었습니다!',
      location: '위치:',
      Scale: '규모:'
  },
  forgotPwd: {
      head: '이메일 검색',
      resetSuccess: '비밀번호를 성공적으로 재설정했습니다!',
      inputPlaceholder: {
          code: '인증번호를 입력해주세요'
      },
      sendCodeError: '잘못된 인증 코드',
      sendCodeError_sended: '인증번호가 발송되었습니다. 확인해주세요',
      button: {
          getCode: '인증 코드 받기',
          reGetCode: '인증코드 다시 받기'
      }
  },
  button: {
      ok: '확인하다',
      cancel: '취소',
      search: '찾다',
      clear: '분명한'
  },
  device: {
      button: {
          setContent: '푸시 일정 구성',
          setGroupMng: '그룹 관리자 설정',
          startNow: '지금 푸시',
          stopNow: '밀지 마세요',
          add: '기기 추가',
          search: '찾다',
          adjust: '그룹화 조정',
          del: '삭제'
      },
      inputPlaceholder: {
          search: '기기번호/휴대전화번호/ViiTALK 번호'
      },
      group: {
          add: '그룹 추가',
          edit: '그룹 수정',
          del: '그룹 삭제'
      },
      table: {
          th_platform:'플랫폼',
          th_ver:'APP 버전',
          th_public:'공용 / 사용자 정의',
          th_name: '장치 이름',
          th_number: '장치 번호',
          th_time: '마지막 로그인 시간',
          th_brandNumber: '컨퍼런스 하우스 번호',
          th_online: '온라인 상태',
          th_status: '상태',
          th_bindNumber: '대형 화면 번호 바인딩'
      },
      dialog: {
          addGroupTitle: '그룹 이름을 입력하세요.',
          inputPlaceholder: {
              groupName: '그룹 이름을 입력하세요.'
          },
          delGroupTitle: '그룹 삭제',
          delGroupTips: '그룹을 삭제하시겠습니까?',
          editGroupTitle: '그룹 수정',
          groupMng: {
              title: '그룹 관리자 수정',
              inputPlaceholder: {
                  name: '관리자 이름을 입력하세요.'
              },
              label: {
                  name: '관리자 이름'
              }
          },
          addDevice: {
              title: '기기 추가',
              inputPlaceholder: {
                  name: '기기 이름을 입력하세요.',
                  number: '기기번호를 입력해주세요'
              },
              label: {
                  name: '장치 이름',
                  number: '장치 번호',
                  group: '장치 그룹을 선택하십시오'
              }
          },
          delDevice: {
              title: '장치를 제거',
              tips: '정말로 기기를 삭제하시겠습니까?'
          },
          startNowDevice: {
              title: '지금 푸시',
              tips: '지금 푸시하시겠습니까?'
          },
          stopNowDevice: {
              title: '밀지 마세요',
              tips: '푸시를 중단하시겠습니까?'
          },
          adjustTitle: '그룹화 조정',
          editDevice: {
              title: '장치 이름 수정',
              inputPlaceholder: {
                  name: '기기 이름을 입력하세요.'
              }
          },
          noChildren: {
              title: '경고하다',
              tips: '먼저 그룹을 추가하세요.'
          }
      },
      validator: {
          group: {
              name_null: '그룹 이름은 비워둘 수 없습니다.',
              name_length: '10자를 초과할 수 없습니다.'
          },
          number_null: '숫자는 비워둘 수 없습니다.',
          number_rule: '6 또는 8로 시작하는 10자리 숫자만 가능합니다.',
          name_null: '터미널 이름은 비워둘 수 없습니다.'
      }
  },
  user: {
      button: {
          add: '사용자 추가'
      },
      inputPlaceholder: {
          search: 'ViiTALK 번호'
      },
      table: {
          th_name: '이름',
          th_number: '휴대폰 번호/Maizhe 번호'
      },
      dialog: {
          addUser: {
              title: '사용자 추가',
              inputPlaceholder: {
                  number: 'ViiTALK 번호를 입력해주세요'
              },
              label: {
                  number: 'ViiTALK 번호',
                  group: '사용자 그룹을 선택해 주세요'
              }
          },
          delUser: {
              title: '사용자 삭제',
              tips: '사용자를 삭제하시겠습니까?'
          }
      },
      validator: {
          number_null: '숫자는 비워둘 수 없습니다.',
          number_rule: '이 번호는 존재하지 않습니다'
      }
  },
  addrbook: {
      return: {
          delDevice: {
              tips_1: '삭제할 그룹이 존재하지 않습니다.',
              tips_2: '삭제할 그룹 아래에 하위 그룹이 있어 삭제할 수 없습니다.',
              tips_3: '해당 그룹에 삭제 대상 인물이 있어 삭제할 수 없습니다.'
          },
          addDevice: {
              tips_1: '이미 추가되었습니다. 다른 그룹을 확인하세요.',
              tips_2: '다른 회사에서 추가했습니다.',
              tips_3: '이 번호는 존재하지 않습니다'
          }
      }
  },
  audit: {
      option: {
          label: {
              NoPass: '통과하지 못함',
              hasRefuse: '거부됨',
              hasPass: '합격',
              hasInvalid: '만료됨',
              all: '모두'
          }
      },
      button: {
          pass: '통과하다',
          reject: '거부하다'
      },
      table: {
          th_number: '장치 번호',
          th_date: '날짜',
          th_status: '상태',
          th_source: '원천'
      },
      dialog: {
          passTitle: '승인됨',
          reject: {
              title: '검토 거부',
              tips: '리뷰를 거부하시겠습니까?'
          }
      },
      api: {
          req: {
              tips_1: '검토를 통과할 수 없습니다. 선택한 항목의 출처가 다릅니다.',
              tips_2: '할당할 지역을 선택하세요.'
          },
          return: {
              tips_1: '장치 번호가 존재하지 않습니다',
              tips_2: '당신은 기업에 합류했습니다',
              tips_3: '이미 추가되었습니다. 다른 그룹을 확인하세요.'
          }
      }
  },
  conferenceLog: {
      inputPlaceholder: '내용을 입력해주세요',
      table: {
          th: {
              roomId: '방 번호',
              state: '방 상태',
              username: '작성자:jid',
              createTime: '회의 생성 시간',
              closeTime: '회의 종료 시간',
              duration: '지속'
          }
      },
      dialog: {
          title: '회원 가입/탈퇴 방 로깅',
          table: {
              th: {
                  username: '작성자:jid',
                  joinTime: '가입하다',
                  leaveTime: '떠나다',
                  duration: '지속'
              }
          }
      }
  },
  callLog: {
      table: {
          th: {
              callerMzNum: '전화번호',
              callTime: '다이얼 시간',
              endTime: '종료 시간',
              duration: '지속',
              responderMzNum: '전화받은 번호'
          }
      }
  },
  roomStatus: {
      table: {
          th: {
              roomMessage: '기본 객실 정보',
              Attendees: '참석자',
              bystander: '방관자',
              join: '첨가되는',
              exited: '종료됨'
          }
      },
      roomNumber: '회의실 번호',
      name: '회의실 이름',
      dissolution: '해산',
      pattern: '모델',
      confluence: '합류',
      forward: '앞으로',
      createdTime: '생성 시간',
      limit: '발매/총 인원 제한',
      createMode: '패턴 만들기',
      order: '약속 (회의)',
      simple: '일반 멀티플레이어',
      type: '회의 유형',
      creator: '창조자',
      host: '주인:',
      windowMode: '창 모드:',
      WindowMode1: '하나의 큰 것과 많은 작은 것',
      WindowMode2: '타일',
      hide: '숨길지 여부:',
      forbidden: '밀 금지 여부:',
      inorder: '주문하다:',
      source: '원천:',
      joinTime: '참여 시간:',
      comeOut: '제발 나와주세요',
      openMic: '마이크 켜 줘',
      closeMic: '마이크 끄기',
      leave: '떠나다:',
      peopleNumber: '인원수:'
  },
  menu: {
      index: '첫 장',
      device_manage: '장치 관리',
      user_manage: '사용자 관리',
      conference_brand: '회의장 번호',
      audit_manage: '감사관리',
      orgMessage: '기업정보',
      schoolMessage: '기업정보',
      roomStatus: '진행중인 회의',
      p2pCallLog: '영상 통화 기록',
      conferenceLog: '회의록',
      authorizedMessage: '승인정보',
      title: '기업 관리 플랫폼',
      schooltitle: '기업 관리 플랫폼',
      account_mangae: '계정 관리',
      contacts: '그룹 주소록',
      records: '통화 기록',
      LicenseInformation: '승인정보',
      pushInfo: '정보 푸시',
      infoGroup: '푸시 그룹 관리'
  },
  download: '앱 다운로드',
  unauthorized: {
      title: 'ViiTALK 기업 관리 플랫폼(비인가)',
      span_1: '귀하의 서버가 ViiTALK 서비스를 승인하지 않는 것으로 감지되었습니다.',
      span_2: '인증 파일을 받으려면 고객 서비스에 문의하세요.',
      span_3: '(인증 파일을 받으려면 서버 IP 주소와 Mac 주소를 고객 서비스에 제공해야 합니다)',
      upError: '죄송합니다. 업로드한 파일이 잘못되었습니다. 다시 업로드해 주세요.',
      chooseFile_b: '문서를 선택하세요',
      upFile_b: '승인 파일 업로드',
      success: {
          span_1: '승인 성공',
          span_2: '기업 관리 플랫폼 진출…',
          span_3: '(오랜 시간 동안 접속하지 않으셨다면 브라우저를 새로고침해주세요)'
      },
      dialog: {
          title: '관리자 비밀번호를 변경해주세요',
          span: '귀하의 관리자 비밀번호는 아직 변경되지 않았습니다. 즉시 비밀번호를 변경하세요.',
          button: '제출하다'
      }
  },
  lang: {
    'zh-CN': '중국어 간체',
    'zh-TW': '중국어 번체',
    en: '영어',
    ja: '일본어',
    ko: '한국인',
    th: '태국어',
    ar: '아라비아 말',
    bn: '벵골 사람',
    de: '독일 사람',
    el: '그리스 어',
    es: '스페인의',
    fa: '페르시아 인',
    fr: '프랑스 국민',
    id: '인도네시아 인',
    it: '이탈리아 사람',
    iw: '헤브라이 사람',
    ku: '쿠르드어',
    nl: '네덜란드 사람',
    pl: '광택',
    pt: '포르투갈 인',
    ro: '루마니아 사람',
    ru: '러시아인',
    tr: '터키 사람',
    vi: '베트남어'
  },
  authorized: {
      org: '허가받은 기업',
      userLimit: '사용자 한도',
      roomMax: '클라우드 회의실 최대 수용 인원',
      roomCountLimit: '최대 온라인 클라우드 회의실 수',
      authorizedActiveDate: '프라이빗 클라우드 라이선스 활성화 날짜',
      authorizedValidDate: '프라이빗 클라우드 라이선스는 다음 날짜까지 유효합니다.',
      button_up: '승인 업데이트',
      upFileError: '파일이 선택되지 않았습니다'
  },
  index_title: 'ViiTalk 기업 관리 플랫폼',
  brand: {
    table: {
        th: {
            brandNumber: '집 번호',
            bindNumber: '대형 화면 번호 바인딩',
            onlineStatus: '온라인 상태',
            lastLoginTime: '마지막 로그인 시간'
        },
        col: {
            content: {
                onlineStatus: {
                    online: '온라인',
                    offline: '오프라인'
                }
            }
        }
    }
  }
}